<template>
  <v-row justify="center">
    <v-col cols="10">
      <v-card class="mb-5">
        <v-card-text class="pa-5">
          <v-toolbar flat>
            <v-toolbar-title>Product Details</v-toolbar-title>
            <v-spacer />
            <v-btn
              text
              color="primary darken-1"
              @click.stop="purchaseProduct(selectedProduct.id)"
            >
              BUY NOW
            </v-btn>
            <v-dialog
              v-model="paymentDialog"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title>Enter Payment Details</v-card-title>
                <v-container ref="paymentCard" />
                <v-card-actions>
                  <v-btn
                    color="darken-1"
                    text
                    @click="paymentDialog = false"
                  >
                    close
                  </v-btn>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="checkout"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <h3
                    class="darken-2"
                    style="font-size: 1rem; color: grey"
                  >
                    Product Name
                  </h3>
                  <br>
                  <p>{{ selectedProduct.name || 'Not Provided' }}</p>
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <h3
                    class="darken-2"
                    style="font-size: 1rem; color: grey"
                  >
                    Description
                  </h3>
                  <br>
                  <p>{{ selectedProduct.description || 'Not Provided' }}</p>
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <h3
                    class="darken-2"
                    style="font-size: 1rem; color: grey"
                  >
                    Price
                  </h3>
                  <br>
                  <p>
                    {{
                      selectedProduct.direct_product
                        ? selectedProducted.cost_price
                        : selectedProduct.sell_price || 'Not Provided'
                    }}
                  </p>
                  <v-divider />
                </v-col>
                <template v-if="selectedProduct.does_expires == true">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h3
                      class="darken-2"
                      style="font-size: 1rem; color: grey"
                    >
                      Expiry
                    </h3>
                    <br>
                    <p>{{ selectedProduct.expiry_date || 'Not Provided' }}</p>
                    <v-divider />
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  // let stripe = Stripe('pk_test_51HPn4PFU21qe166Hrgn5AzWRUKNfXMe6qmtbIGZVGoBgReQPSI6MLQjv8VjBMyvn8YSzStXy2mc54dyiD50P04Bb00DtAa8phj');
  // let elements = stripe.elements();
  // let card = undefined;

  export default {
    name: 'ProductDetails',
    data () {
      return {
        paymentDialog: false,
      }
    },
    created () {
      this.viewProduct(this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedProduct', 'clientSecret']),
    },
    methods: {
      ...mapActions([
        'createRegistrantOrder',
        'viewProduct',
        'createRegistrantOrder',
      ]),
      purchaseProduct (productId) {
        this.paymentDialog = true
        const options = {
          style: {
            base: {
              color: '#FFFFFF',
            },
          },
          hidePostalCode: true,
        }
        this.$nextTick(function () {
        // card = elements.create('card', options);
        // card.mount(this.$refs.paymentCard);
        })
        this.createRegistrantOrder({ product_id: productId })
      },
      checkout () {
      // was commented to begin with
      // stripe.createToken(card).then(() => {
      //   const tokenCard = result
      //   console.log(tokenCard)
      //   stripe.confirmCardPayment(this.clientSecret, {
      //     card: tokenCard,
      //   }).then(res => {
      //     if (res.error) {
      //       console.log('errror')
      //     } else {
      //       if (res.paymentIntent.status === 'succeeded') {
      //         console.log('Worked!')
      //       }
      //     }
      //   })
      // })
      },
    },
  }
</script>

<style></style>
